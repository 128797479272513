const getDomain = () => {
  let href = window.location.href
  // href = 'http://www.biddinghost.com/'
  let url = new URL(href)
  let host = url.host
  // .分割，获取其中最长的部分
  let urlArr = host.split('.')
  urlArr.sort((a, b) => {
    return b.length - a.length
  })
  return urlArr[0]
}

module.exports = {
  getDomain
}
