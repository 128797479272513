import {getDomain} from '../../utils/common'
let domain = getDomain()
let str =  domain.toLowerCase().replace(/( |^)[a-z]/g, (L) =>  L.toUpperCase())
let title = domain
let Title = str

const state = {
  set(data) {
    console.log(data)
  },
  choicelang: 0,
  title,
  Title,
  contents: [{
    lang: 'English',
    id: 0,
    con: {
      one: {
        a: 'Log In',
        b: 'Language',
        c: 'Contact Us',
        d: 'Monetize Your Mobile Products Maximally',
        da: {
          a: 'Monetize Your Mobile Products Maximally',
          b: ''
        },
        e: 'Leading Data-Driven Mobile Advertising Integration Platform',
        mynav: {
          a: `About ${Title}`,
          b: 'Maximum Revenue Strategy',
          c: 'Our Partners'
        }
      },
      two: {
        a: 'Engagement & Monetization',
        b: 'We know building great apps takes time. So we have the easiest way for integration. Let us get started now!',
        c: 'We mediate the most popular mobile Ad networks to ensure high fill rate and high eCPM',
        d: 'Our lightweight SDK, Flexible API and JS Tag for all developers, turn non-paying users into your outstanding income source'
      },
      three: {
        a: `${Title} in Numbers`,
        b: [
          {
            a: 'Countries covered',
            b: 166,
            c: '+'
          },
          {
            a: 'ADS served per hour',
            b: 1,
            c: 'B+'
          },
          {
            a: 'Fill-Rate',
            b: 90,
            c: '%+'
          },
          {
            a: 'Monthly Active Users',
            b: 600,
            c: 'Million+'
          },
          {
            a: 'APPs using our SDK',
            b: 1800,
            c: '+'
          },
          {
            a: 'Support',
            b: 24,
            c: '/7/365'
          }
        ]
      },
      seven: {
        a: 'Powerful Realtime Reports ',
        b: 'Our reports are updated every hour, so you can make informed decisions in real time'
      },
      four: {
        a: 'Maximum Revenue Strategy',
        b: 'We deliver most suitable ads to the most matched users',
        c: 'We use predictive algorithms to identify and analyze the targeted audience'
      },
      mynavtwo: [{
        text: 'User Analysis'
      },
      {
        text: 'Machine Learning'
      },
      {
        text: 'Precise Matching'
      }],
      five: {
        a: 'Based on the continual analysis of targeted users, we deliver the most matched ads. Our machine learning technique will assure your effective revenue growth',
        b: ''
      },
      six: {
        a: 'Our Partners'
      },
      footer: [{
        a: 'EMAIL',
        b: `support@${title}.com`
      }
      // {
      //   a: 'PHONE NUMBER',
      //   b: '(+86)010-82860172'
      // },
      // {
      //   a: 'QQ',
      //   b: '348568688'
      // },
      // {
      //   a: 'SKYPE',
      //   b: `support@${title}.com`
      // },
      // {
      //   a: 'ADDRESS',
      //   b: 'A 1101,Zhongguancun SoHo,',
      //   c: '',
      //   d: 'Haidian District',
      //   e: 'Beijing',
      //   f: 'China'
      // }
      ]
    }
  },
  {
    lang: '中文',
    id: 1,
    con: {
      one: {
        a: '登录',
        b: '语言',
        c: '联系我们',
        d: '最大限度地为您的产品变现',
        da: {
          a: '最大限度地',
          b: '为您的产品变现'
        },
        e: '领先的数据驱动移动广告聚合平台',
        mynav: {
          a: `关于${Title}`,
          b: '收益最大化策略',
          c: '合作伙伴'
        }
      },
      two: {
        a: '聚合 & 变现',
        b: `作为一家数据驱动的移动广告变现平台，${Title}清楚地知道如何利用大数据不断地为您的产品创造价值`,
        c: '聚合Facebook和Admob两大广告平台，为您量身定制变现解决方案',
        d: '提供SDK, JS Tag 和 API等多种对接方式，满足不同开发者的各种需求'
      },
      three: {
        a: `数说${Title}`,
        b: [
          {
            a: '广告覆盖国家达',
            b: 197,
            c: '+'
          },
          {
            a: '每小时广告请求',
            b: 10,
            c: '亿+'
          },
          {
            a: '填充率',
            b: 91,
            c: '%+'
          },
          {
            a: '月活用户',
            b: 9,
            c: '亿+'
          },
          {
            a: 'APPs使用我们的SDK变现',
            b: 2200,
            c: '+'
          },
          {
            a: '技术支持',
            b: 24,
            c: '/7/365'
          }
        ]
      },
      seven: {
        a: '方便透明的实时报表',
        b: '我们提供实时报表，方便您随时随地查看收入情况，为您的运营提供数据支持'
      },
      four: {
        a: '收益最大化策略',
        b: '我们把最合适的广告投给最匹配的人群',
        c: '我们使用预测算法识别、分析用户'
      },
      mynavtwo: [{
        text: '用户分析'
      },
      {
        text: '机器学习'
      },
      {
        text: '精准匹配'
      }],
      five: {
        a: '基于持续的用户分析投放匹配广告',
        b: '以机器学习技术确保高效的收入增长'
      },
      six: {
        a: '合作伙伴'
      },
      footer: [{
        a: '邮箱',
        b: `support${title}.net`
      },
      {
        a: '电话',
        b: '(+86)010-82860172'
      },
      {
        a: 'QQ',
        b: '348568688'
      },
      {
        a: 'SKYPE',
        b: `support@${title}.net`
      },
      {
        a: '地址',
        b: '北京市',
        c: '海淀区',
        d: '中关村SOHO A区1101',
        e: '100080'
      }]
    }
  }]
}

export default state
