<template>
  <div id="app" class="cursorurl container app"
  v-on:click="closenavfun()"
  v-bind:class="{appmac:platformmac}">
    <one
    v-on:clickscrollto="scrollto"
    v-bind:scroll="scroll"
    v-bind:offsetTop="offsetTopone"
    v-bind:offsetToptwo="offsetToptwo"
    v-bind:offsetTopfooter="offsetTopfooter"
    v-on:submitlang="changelang"></one>
    <!--<mynav v-on:clickscrollto="scrollto" v-bind:offsetToptwo="offsetToptwo" v-bind:offsetTopfour="offsetTopfour" v-bind:offsetTopsix="offsetTopsix"></mynav>-->
    <two
    v-bind:scroll="scroll"
    v-bind:offsetTop="offsetToptwo"></two>
    <three
    v-bind:scroll="scroll"
    v-bind:offsetTop="offsetTopthree"></three>
    <seven
    v-bind:scroll="scroll"
     v-bind:offsetTop="offsetTopseven"></seven>
    <four
    v-bind:scroll="scroll"
    v-bind:offsetTop="offsetTopfour"></four>
    <mynavtwo></mynavtwo>
    <five
    v-bind:scroll="scroll"
    v-bind:offsetTop="offsetTopfive"></five>
    <!-- <six
    v-bind:scroll="scroll"
    v-bind:offsetTop="offsetTopsix"></six> -->
    <myfooter></myfooter>
  </div>
</template>
<script>
import Vue from 'vue'
// 载入vuex
import Vuex from 'vuex'
// 调用vuex
Vue.use(Vuex)
// 引入store
import store from './store'
// 全局css
import './css/base.scss'
import { mapGetters, mapActions } from 'vuex'
// 页面组件
import one from './components/One.vue'
import two from './components/Two.vue'
import three from './components/Three.vue'
import seven from './components/Seven.vue'
import four from './components/Four.vue'
import mynavtwo from './components/Mynavtwo.vue'
import five from './components/Five.vue'
import six from './components/Six.vue'
import myfooter from './components/Myfooter.vue'
//
export default{
  store,
  data () {
    return {
      scroll: 0,
      offsetTopone: 0,
      offsetToptwo: 0,
      offsetTopthree: 0,
      offsetTopseven: 0,
      offsetTopfour: 0,
      offsetTopfive: 0,
      offsetTopsix: 0,
      offsetTopfooter: 0,
      platformmac: false
    }
  },
  components: {
    one,
    two,
    three,
    seven,
    four,
    mynavtwo,
    five,
    six,
    myfooter
  },
  computed: {
    ...mapGetters([
      'Title', 'title'
    ])
  },
  mounted: function () {
    // 设置title
    this.setTitle()
    // 设置容器的高度
    this.setheight()
    // 实时获取scrolltop的值
    this.getscrolltop()
    // 调用语言判断
    this.judelang()
    // 判断平台
    this.judeplatform()
  },
  methods: {
    ...mapActions(['increment']),
    // 设置title
    setTitle: function () {
      document.title = this.Title
    },
    // 设置容器的高度
    setheight: function () {
      let vm = this
      // 获取当前window的高
      let windowh = window.innerHeight
      if (windowh < 580) {
        windowh = 580
      }
      //
      let one = document.getElementsByClassName('one')
      one[0].style.height = windowh + 'px'
      let two = document.getElementsByClassName('two')
      let three = document.getElementsByClassName('three')
      let seven = document.getElementsByClassName('seven')
      let four = document.getElementsByClassName('four')
      let five = document.getElementsByClassName('five')
      // let six = document.getElementsByClassName('six')
      let footer = document.getElementsByClassName('footer')
      // 获取当前页面相对于document最顶部的距离
      vm.offsetTopone = one[0].offsetTop
      vm.offsetToptwo = two[0].offsetTop - 300
      vm.offsetTopthree = three[0].offsetTop - 300
      vm.offsetTopseven = seven[0].offsetTop - 300
      vm.offsetTopfour = four[0].offsetTop - 300
      vm.offsetTopfive = five[0].offsetTop - 300
      // vm.offsetTopsix = six[0].offsetTop - 300
      vm.offsetTopfooter = footer[0].offsetTop - 300
    },
    // 实时获取scrolltop的值
    getscrolltop: function () {
      let vm = this
      // 获取scrolltop函数
      this.scrollFunc = function () {
        let scrolltop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        scrolltop = parseInt(scrolltop)
        vm.$set(vm, 'scroll', scrolltop)
      }
      window.onscroll = function () {
        vm.scrollFunc()
      }
    },
    changelang: function () {
      // 改变语言
      // console.log(arr[2])
      this.judelang()
    },
    judelang: function () {
      // 页面元素样式调整
      // 改变font-family
      // var body = document.body
      // 读取localstorage的lang的值，假如没值的话,把state中的choicelang设置为localstorage的lang的值
      let lang = localStorage.getItem('lang')
      // 强制英文
      // lang = '0'
      // console.log(lang)
      // console.log(this.$store.state.choicelang)
      // 如果本地没有
      if (!lang) {
        // 第一次打开，默认为英文
        let statelang = this.$store.state.choicelang
        localStorage.setItem('lang', statelang)
      } else {
        this.$store.state.choicelang = lang
      }
      // console.log(this.$store.state.state.choicelang)
    },
    // 页面滚动
    // window.scrollTo
    scrollto: function (arr) {
      var ev = arr[0]
      var offsetTop = arr[1]
      if (window.scrollTo) {
        // console.log(offsetTop)
        // 阻止浏览器默认行为
        ev.preventDefault()
        window.scrollTo(0, offsetTop)
      }
    },
    // 判断终端
    judeplatform: function () {
      var vm = this
      var u = navigator.userAgent.toLowerCase()
      // console.log(ua)
      if (u.match(/mac os/i)) {
        // 是微信打开
        vm.platformmac = true
      }
    },
    // 关闭导航栏
    closenavfun: function () {
      for (var i = 0; i < this.$children.length; i++) {
        if (this.$children[i].closenavfun) {
          this.$children[i].closenavfun()
        }
      }
    }
  },
  watch: {
    scroll: function () {
      // console.log(this.scroll)
      // 如果滚动了，却没有触发scroll改变，这里就该行动了
    }
  }
}
</script>
<style lang="scss">
.bottom-border{
  width:40px;
  height:3px;
  background:#418cea;
  margin:0 auto;
}
.bgimg1{
    background-size:100% auto;

}
#app{
    -webkit-overflow-scrolling : touch;
}
// 自定义鼠标样式
.cursorurl{
    // cursor:url('./img/thislogo.png'), default;
    // cursor:url('../dist/thislogo.png'), default;
}
.aistart{
    justify-content: flex-start !important;

}
.jcstart{
    align-items: flex-start !important;

}
.fonttitle{
    font-size: 4rem;
    padding:1rem 0;
}
.fontdescription{
    font-size: 2rem;
    padding:1rem 0;
}
.bg31{
    background: #313131;
}
.cblod{
    color:#313131;
}
.hw-p{
  padding:8rem 0;
}
.hw-p-top{
  padding:4rem 0 0 0;
}
.hw-p-bottom{
  padding:0 0 4rem 0;
}
//屏幕尺寸
$size-l:1200px;
$size-m:980px;
$size-s:760px;
$size-ss:370px;
//
@media screen and (max-width:$size-l) {
    .footer-box{
        width:50% !important;
    }
    .two-down-con-imgbox-word{
      font-size: 1.6rem !important;
    }
    .two-down-con-imgbox-word{
      height:50% !important;
    }
}
@media screen and (max-width:$size-m) {
    .footer-box{
        width:50% !important;
    }
    .siximg-centerbox{
        width:250px !important;
    }
    .sixbannerbox{
        width:250px !important;
        padding: 0 15px !important;
        top: 60px !important;
    }
    .six-imgbox{
        margin:5rem 0 !important;
    }
    .two-down-con-imgbox-word{
      font-size: 1.2rem !important;
    }
}
@media screen and (max-width:$size-s) {
    .fonttitle{
        font-size: 2.6rem;
    }
    .fontdescription{
        font-size: 1.4rem !important;
        div{
            padding:0;
        }
    }
    .godownBox{
      .go-down-box{
        width:100px !important;
        .godown{
          padding:3rem 0 !important;
        }
      }
    }
    .threea{
        font-size: 1.4rem !important;
    }
    .threeb{
        font-size: 2.6rem !important;
    }
    .nav{
      .nav-box{
        width:30px;
      }
      .nav-right-box{
        transform: rotateY(90deg) rotateX(90deg);
        transform-origin: right top;
        opacity: 0;
        position: absolute;
        right:0;
        top:100%;
        width:50%;
        margin:0 !important;
        transition: all .5s ease;
        .navright-box{
          margin:0 !important;
          padding:1px 0 0 0 !important;
          width:100%;
          .navrightbox{
            background: rgba(0,0,0,0.5);
            padding:1rem 0 !important;
          }
        }
      }
      .navright-box:last-child{
          margin-right:0 !important;
      }
      .navrightbox-border{
          display: none;
      }
    }
    .changenav{
      .navrightbox{
        background: rgba(255,255,255,1) !important;
      }
    }
    .five-dec{
        padding:0;
    }
    .six-imgbox{
        height:auto !important;
    }
    .siximgbox{
        width:150px !important;
        img{
            padding:0.5rem !important;
        }
    }
    .siximg-centerbox{
        height:300px !important;
    }
    .six-imgbox{
        margin:0 !important;
    }
    .hw-p{
        padding:4rem 0;
    }
    .logo{
        width:220px !important;
    }
    .threebox{
        padding:2rem 0 !important;
    }
    .four-down-right-box{
        margin-top:4rem !important;
    }
    .fiveimgbox{
        margin-top:4rem !important;
    }
    .two-down-con{
      padding:2rem 0 !important;
      margin:2rem auto !important;
    }
    .two-down-con-imgbox-word{
      font-size: 1.2rem !important;
    }
}
@media screen and (max-width:$size-ss) {
  .godownBox{
    .go-down-box{
      width:100px !important;
      .godown{
        padding:1rem 0 !important;
      }
    }
  }
  .nav{
    .navright-box:last-child{
      margin:0 !important;
    }
    .navrightbox-border{
      display: none;
    }
  }
  .col-s-text-center{
    text-align: center !important;
  }
  .four-down{
    height:400px !important;
  }
}
// css3动画
@keyframes animatescale {
    0%{
        transform: scale(0);
    }
    50%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
}
@keyframes animaterotate {
    0%{
        transform: scale(1);
        transform:rotateZ(0deg);
    }
    100%{
        transform: scale(1);
        transform:rotateZ(360deg);
    }
}
@keyframes animaterotateAnti {
    0%{
        transform: scale(1);
        transform:rotateZ(0deg);
    }
    100%{
        transform: scale(1);
        transform:rotateZ(-360deg);
    }
}
// 动画名称，动画用时，动画速度曲线，动画开始延迟，动画播放次数，是否应该轮流反向播放动画，动画结束后保存状态
@mixin animate($name,$duration,$timingfunction,$delay,$iterationcount,$direction,$fillmode) {
    .#{$name}_#{$duration}ms_#{$delay}ms{
        animation: #{$name} #{$duration}ms #{$timingfunction} #{$delay}ms #{$iterationcount} #{$direction} #{$fillmode};
    }
}
.animatescale{
    transform: scale(0);
}
@for $i from 0 through 10 {
    @for $j from 0 through 10 {
        @include animate("animatescale",$i*500,"ease",$j*500,"","","forwards");
        @include animate("animaterotate",$i*500,"linear",$j*500,"infinite","","forwards");
        @include animate("animaterotateAnti",$i*500,"linear",$j*500,"infinite","","forwards");
    }
}
// 禁止被选中
*{
    user-select:none;
    -webkit-user-select:none;
}
</style>
