import { render, staticRenderFns } from "./Seven.vue?vue&type=template&id=36273a66&scoped=true&"
import script from "./Seven.vue?vue&type=script&lang=js&"
export * from "./Seven.vue?vue&type=script&lang=js&"
import style0 from "./Seven.vue?vue&type=style&index=0&id=36273a66&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.4_webpack@5.73.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36273a66",
  null
  
)

export default component.exports