<template >
  <div class="one flex-center cf bgimg pr" ref="one">
    <div class="navBox" v-bind:class="{changenav:changenav}">
      <div class="clearfix nav col-auto-18 col-s-22 h-auto-100">
        <!--左边的logo-->
        <div class="logo db fl flex-center h-auto-100 pr">
          <img v-if='logo' :src="logo" alt="" class="logo-img">
          <span v-else class="logo-title">{{Title}}</span>
        </div>
        <!--右边的内容-->
        <div class="nav-box fr clearfix h-auto-100" v-bind:class="{shownavbox:shownav}">
          <div class="nav-button-box col-auto-hide col-s-show fr h-auto-100" v-on:click="shownavfun($event)">
            <div class="nav-button flex-center h-auto-100">
              <div class="db nav-css3-iconbox flex-center">
                <div class="nav-css3-icon"></div>
                <div class="nav-css3-icon"></div>
                <div class="nav-css3-icon"></div>
              </div>
            </div>
          </div>
          <div class="nav-right-box flex-center fr h-auto-100" v-bind:class="{shownav:shownav}">
            <div class="langcheck navright-box h-auto-100"
            v-for="(item,index) in state.contents"
            @click="changelang($event,item,index)"
            v-bind:class="{langcheck0:activelang0,langcheck1:activelang1}">
              <div class="navrightbox flex-center h-auto-100">
                <span>{{item.lang}}</span>
              </div>
              <div class="navrightbox-border"></div>
            </div>
            <!-- <div class="navright-box h-auto-100" v-on:click="shownavfun()">
              <div class="navrightbox flex-center h-auto-100">
                <a href="./login.html">{{contents.con.one.a}}</a>
              </div>
              <div class="navrightbox-border"></div>
            </div> -->
            <div class="navright-box h-auto-100" v-on:click="shownavfun($event)">
              <div class="navrightbox flex-center h-auto-100" v-on:click="scrollto($event,offsetTopfooter+2000)">
                <a href="#footer">{{contents.con.one.c}}</a>
              </div>
              <div class="navrightbox-border"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner text-center col-auto-24 col-s-22 flex-column">
      <div class="fonttitle cf menubox col-auto-22 flex-center">
        <div class="col-auto-show col-s-hide">
          <theater v-bind:choicelang="state.choicelang" v-bind:contents="contents"></theater>
        </div>
        <div class="col-auto-hide col-s-show" v-bind:class="{menusmall:menustyle}">
          <div class="">{{contents.con.one.da.a}}</div>
          <div class="">{{contents.con.one.da.b}}</div>
        </div>
      </div>
      <div class="fontdescription cf">
        {{contents.con.one.e}}
      </div>
    </div>
    <div class="godownBox flex-column">
      <a class="go-down-box flex-column db" href="#two" v-on:click="scrollto($event,offsetToptwo+340)">
        <div class="getstart">
          <img src="../img/getstart-EN.png" alt="" v-if="lang == 0">
          <img src="../img/getstart-CH.png" alt="" v-if="lang == 1">
        </div>
        <div class="godown">
          <img src="../img/go-down.png" alt="">
        </div>
      </a>
    </div>

  </div>
</template>

<script>
import theater from './Theater.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['scroll', 'offsetTop', 'offsetToptwo', 'offsetTopfooter'],
  data () {
    return {
      shownav: false,
      changenav: false,
      menustyle: false,
      activelang0: false,
      activelang1: false
    }
  },
  computed: {
    state () {
      return this.$store.state
    },
    ...mapGetters([
      'contents', 'lang', 'Title', 'title'
    ]),
    logo() {
      let name = this.title
      console.log(name)
      let img =''
      try {
        img = require(`../img/newimg/${name}.png`)
        console.log(img)
      } catch(e) {
        console.log(e)
      }
      return img
    }
  },
  components: {
    theater
  },
  methods: {
    ...mapActions(['increment']),
    // 映射 this.increment() 为 this.$store.dispatch('increment')
    // ...mapActions ({add:'increment'}),
    // 映射 this.add() 为 this.$store.dispatch('increment')
    changelang: function (e, item, index) {
      this.increment(item.id)
      // 此处调用action，改变lang的状态
      // this.$store.dispatch('increment')
      // 此处调用mutations
      // store.commit('increment')
      // 手动向父组件触发事件
      this.$emit('submitlang', [e, item, index])
      // 隐藏语言切换盒子
      // var changelangbox=document.getElementsByClassName("changelangbox")[0]
      // changelangbox.style.display="none"
      // 关闭导航栏下拉菜单
      this.shownav = false
    },
    shownavfun: function (e) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      // console.log(this.shownav)
      if (this.shownav) {
        this.shownav = false
      } else {
        this.shownav = true
      }
    },
    closenavfun: function () {
      // 关闭导航栏下拉菜单
      this.shownav = false
    },
    scrollto: function (e, offsetTop) {
      // 手动向父组件触发事件
      this.$emit('clickscrollto', [e, offsetTop])
    },
    judelang: function () {
      if (parseInt(this.lang) === 1) {
        // 汉语
        this.menustyle = false
        this.activelang0 = false
        this.activelang1 = true
      } else {
        // 英语
        this.menustyle = true
        this.activelang0 = true
        this.activelang1 = false
      }
    }
  },
  mounted: function () {
    this.judelang()
  },
  watch: {
    scroll: function () {
      if (this.scroll > this.offsetTop) {
        // 此处执行动画
        this.changenav = true
      } else {
        this.changenav = false
      }
      this.shownav = false
    },
    lang: function () {
      this.judelang()
    }
  }
}
</script>
<style lang="scss" scoped>
  .one{
    background-image: url(../img/assets/shouye.png);
    position: relative;
    overflow: hidden;
    height: 700px;
  }
  .one-maskbox{
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    .one-yun{
      width:100%;
      height:auto;
    }
  }
  .godownBox{
    width:100%;
    position: absolute;
    bottom:0;
    left:0;
    .go-down-box{
      width:160px;
    }
    .getstart{
      width:100%;
    }
    @keyframes updown {
        0%{
          transform: translateY(-10px);
        }
        100%{
          transform: translateY(10px);
        }
    }
    .godown{
      width:50%;
      padding:4rem 0;
      img{
        transform: translateY(-10px);
        animation:updown ease .6s infinite;

      }
    }
  }
  .navBox{
    position:fixed;
    z-index: 99;
    left:0;
    top:0;
    width:100%;
    height:50px;
    background: rgba(0,0,0,0.5);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
    transition: all .5s ease;
  }
  .logo{
    width:200px;
    height: 100%;
    // background-image: url('../img/assets/logo.png');
    background-size: auto 150%;
    background-position:center;
    background-repeat: no-repeat;
  }
  .navright-box{
    margin:0 5rem 0 0;
    position: relative;
    padding:0;
  }
  .nav-right-box{
    width:auto;
  }
  .navright-box:last-child{
    margin:0;
  }
  .navrightbox{
    cursor: pointer;
  }
  .navrightbox-border{
    width:100%;
    height:3px;
    background:#fff;
    opacity:0;
    position: absolute;
    left:0;
    bottom:0;
  }
  .navright-box:hover{
    & .navrightbox-border{
      opacity:1;
    }
  }
  .menubox{
    font-size: 3.6rem;
    line-height: 1.2;
    font-weight: bold;
  }
  .menusmall{
    width:185px !important;
  }
  .banner{
    height:auto;
  }
  .nav-css3-iconbox{
    width:20px;
    height:22px;
    .nav-css3-icon{
      width:100%;
      height:2px;
      background: #fff;
      transform: rotateZ(0deg) translate(0,0);
      transform-origin: center;
      opacity: 1;
      transition: all .5s ease;
    }
  }
  .nav-box{
    width:auto;
    height:100%;
  }
  .nav-button-box{
    width:40px;
    height:100%;
  }
  .nav-button{
    width:100%;
    height:100%;
  }
  .shownavbox{
    .nav-css3-icon:nth-child(1){
      transform: rotateZ(45deg) translate(5px,5px);
      transform-origin: center;
    }
    .nav-css3-icon:nth-child(2){
      transform: rotateZ(-180deg);
      opacity: 0;
    }
    .nav-css3-icon:nth-child(3){
      transform: rotateZ(-45deg) translate(5px,-5px);
      transform-origin: center;
    }
  }
  .shownav{
    display: flex;
    opacity: 1 !important;
    transform: rotateY(0deg) rotateX(0deg) !important;
  }
  .changenav{
    background: rgba(255,255,255,1);
    color: #000;
    height:60px;
    .nav-css3-icon{
      background: #000;
    }
    .navright-box{
      color:#000;
    }
    a{
      color:#000;
    }
    .navrightbox-border{
      background: #000;
    }
  }
  .langcheck0:nth-child(1){
    span{
      color:#038bff !important;
    }
  }
  .langcheck1:nth-child(2){
    span{
      color:#038bff !important;
    }
  }
  .logo-img{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: auto;
  }
  .logo-title{
    font-size: 2em;
    font-weight: bold;
  }
</style>
