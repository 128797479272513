export const contents = state => {
  for (var i = 0; i < state.contents.length; i++) {
    if (parseInt(state.contents[i].id) === parseInt(state.choicelang)) {
      // console.log(state.state.choicelang)
      // console.log(state.state.contents[i])
      return state.contents[i]
    }
  }
}
export const lang = state => {
  return state.choicelang
}

export const Title = state => {
  return state.Title
}

export const title = state => {
  return state.title
}
