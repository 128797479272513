import Vue from 'vue'
import App from './App.vue'
// 载入vuex
import Vuex from 'vuex'
// 引入store
import store from './store'

// 全局css
import './css/base.scss'

// 调用vuex
Vue.use(Vuex)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  template: '<App/>',
  components: {
    App
  },
  store
})
