<template >
  <div class="two container hw-p-top bgimg">
    <div class="flex-center col-auto-20 col-s-20 h-auto-30 h-s-20">
      <div class="two-up-box animatescale" v-bind:class="{animatescale_500ms_500ms:animatescale}">
        <div class="fonttitle text-center cblod">
          {{contents.con.two.a}}
        </div>
        <!--<div class="bottom-border"></div>-->
        <div class="two-fontdescription fontdescription text-center">
          {{contents.con.two.b}}
        </div>
      </div>
    </div>
    <div class="flex container clearfix dockerbox h-auto-70 h-s-80 col-auto-24 col-xl-20 col-l-24">
      <div class="col-auto-12 fl col-s-24 h-auto-100 h-s-50">
        <div class="two-down-con h-auto-70 col-auto-18 db pr animatescale pr" v-bind:class="{animatescale_500ms_500ms:animatescale}">
          <img src="../img/assets/two2.png" alt="" class="two-img">
          <!-- <img src="../img/assets/two3.png" alt="" class="useLogo"> -->
          <div class="useLogo"></div>
          <div class="col-auto-22 col-s-24 two-down-con-imgbox-word pa flex-center"  v-text="contents.con.two.c"></div>
        </div>
      </div>
      <div class="col-auto-12 fl col-s-24 h-auto-100 h-s-50">
        <div class="two-down-con h-auto-70 col-auto-18 db pr animatescale" v-bind:class="{animatescale_500ms_500ms:animatescale}">
          <img src="../img/assets/two1.png" alt="" class="two-img" >
          <div class="col-auto-22 col-s-24 two-down-con-imgbox-word pa flex-center"  v-text="contents.con.two.d"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['scroll', 'offsetTop'],
  data () {
    return {
      flage: true,
      animatescale: false
    }
  },
  computed: {
    ...mapGetters([
      'contents'
    ])
  },
  methods: {

  },
  watch: {
    scroll: function () {
      if (this.flage) {
        if (this.scroll >= this.offsetTop) {
          this.flage = false
          // 此处执行动画
          // console.log(this.scroll)
          this.animatescale = true
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
    .two{
      background-image: url(../img/two.png);
      background-size: auto 100%;
    }
    .two-down-con{
      margin:6rem auto;
      .two-img{
        width:100%;
        height:auto;
      }
      .two-down-con-imgbox-word{
        width:100%;
        height:40%;
        padding:0 4rem;
        left:0;
        bottom:0;
        font-size: 1.5rem;
        text-align: center;
      }
    }
    .useLogo{
      position: absolute;
      top:34%;
      right:19%;
      width:14%;
      height:16%;
      background-color: #fff;
    }
</style>
