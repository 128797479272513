<template >
  <div class="seven container bgimg hw-p">
    <div class="col-auto-20 animatescale" v-bind:class="{animatescale_500ms_500ms:animatescale}">
      <div class="fonttitle text-center">
        {{contents.con.seven.a}}
      </div>
      <div class="fontdescription text-center">
        {{contents.con.seven.b}}
      </div>
    </div>
    <div class="seven-down" v-if="lang == 0">
      <div class="col-auto-18 col-m-20 col-s-22 col-xl-12 col-l-16">
        <div class="pr seven-down-imgbox">
          <img src="../img/ecpm-EN.png" alt="" class="seven-ecpm pr animatescale" v-bind:class="{animatescale_500ms_500ms:animatescale}">
          <img src="../img/ecpm-shadow-EN.png" alt="" class="seven-ecpm-shadow pa animatescale" v-bind:class="{animatescale_500ms_1000ms:animatescale}">
          <img src="../img/$4.50.png" alt="" class="seven-see pa animatescale" v-bind:class="{animatescale_500ms_1500ms:animatescale}">
        </div>
      </div>
    </div>
    <div class="seven-down" v-else="lang == 1">
      <div class="col-auto-18 col-m-20 col-s-22 col-xl-12 col-l-16">
        <div class="pr seven-down-imgbox">
          <img src="../img/ecpm-CH.png" alt="" class="seven-ecpm pr animatescale" v-bind:class="{animatescale_500ms_500ms:animatescale}">
          <img src="../img/ecpm-shadow-CH.png" alt="" class="seven-ecpm-shadow pa animatescale" v-bind:class="{animatescale_500ms_1000ms:animatescale}">
          <img src="../img/$4.50.png" alt="" class="seven-see pa animatescale" v-bind:class="{animatescale_500ms_1500ms:animatescale}">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['scroll', 'offsetTop'],
  data () {
    return {
      animatescale: false,
      animaterotate: false
    }
  },
  computed: {
    ...mapGetters(['contents', 'lang'])
  },
  methods: {

  },
  watch: {
    scroll: function () {
      if (this.scroll >= this.offsetTop) {
        // console.log(this.scroll)
        this.animatescale = true
        const that = this
        setTimeout(function () {
          that.animaterotate = true
        }, 500)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .seven-down{
    padding-top:4rem;
    img{
      width:100%;
      height:auto;
    }
    .seven-ecpm{
      width:95%;
      height:auto;
      z-index: 2;
    }
    .seven-ecpm-shadow{
      width:95%;
      height:auto;
      right:0;
      top:0;
      z-index: 1;
    }
    .seven-down-imgbox{
      padding:4rem 0;
    }
    .seven-see{
      bottom:0;
      right:20%;
      height:30%;
      width:auto;
      z-index: 3;
    }
  }
</style>
