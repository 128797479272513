<template >
  <div class="three container bgimg hw-p-top">
    <div class="dockerbox threetitle h-auto-30 h-s-20">
      <div class="text-center fonttitle">
        {{contents.con.three.a}}
      </div>
    </div>
    <div class="dockerbox container threeBox h-auto-70 h-s-70 col-s-24 col-l-20 col-xl-16">
      <div class="col-auto-8 col-m-12 col-s-24 threebox h-auto-50 h-s-33 fl" v-for="(text, index) in contents.con.three.b">
          <div class="three-box text-center flex-column">
            <div class="threeicon"></div>
            <div class="threea">
              <span v-text="text.a"></span>
            </div>
            <div class="threeb">
              <tweennum v-bind:gonum="text.b" v-bind:scroll="scroll" v-bind:offsetTop="offsetTop" v-bind:choicelang="state.choicelang">
              </tweennum><span v-text="text.c"></span>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tweennum from './Tweennum.vue'
export default {
  props: ['scroll', 'offsetTop'],
  data () {
    return {
      num: 0,
      animatescale: false,
      animaterotate: false
    }
  },
  computed: {
    state () {
      return this.$store.state
    },
    ...mapGetters(['contents'])
  },
  components: {
    tweennum
  },
  methods: {

  },
  watch: {
    scroll: function () {
      if (this.scroll >= this.offsetTop) {
        // console.log(this.offsetTop)
        this.animatescale = true
        const that = this
        setTimeout(function () {
          that.animaterotate = true
        }, 500)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
    .threebox{
        padding:4rem 0;
    }
    .three-box{
        justify-content: space-between;
    }
    @for $i from 1 through 6 {
      .threebox:nth-child(#{$i}){
        .threeicon{
            width:40px;
            height:40px;
            background-size: 30px auto;
            margin:1rem auto;
            background-image: url(../img/three#{$i}.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
      }
    }
    .threea{
        font-size: 1.8rem;
        min-width:1px;
        height:2rem;
    }
    .threeb{
        font-size: 4.5rem;
        color:#313131;
    }
</style>
